<template>
  <div>
    <div class="usage-report"
     v-if="activeProject && activeProject.loaded &&
      activeProject.usage && activeProject.usage.name !== 'Error'">
      <div class="usage-report__api-usage">
        <h3 class="api-usage__title">Usage for
          <date-picker @monthchange="fetchUsage($event)" :activeMonth="activeMonth"
            :projectCreatedDate="activeProject.created"
          />
        </h3>
        <template v-if="activeProject.loaded && activeProject.usage
         && activeProject.usage.resource_wise_spending && chartLoaded">
          <api-usage
            v-if="activeProject.loaded && activeProject.usage"
            :activeMonth="activeMonth"
            :maxLen="Object.keys(activeProject.usage.resource_wise_spending).length" />
        </template>
        <div class="loader usage-loader" v-else>
          <div class="skeleton-box"></div>
        </div>
      </div>
      <div class="daily-usage" v-if="!activeProject.project_license.license_key.isPackage">
        <h3 class="chart-header">API Usage</h3>
        <div class="daily-usage__chart-wrapper" v-if="chartLoaded && activeProject.loaded">
          <div class="daily-usage__amount-wrapper">
          <h4>Total Spent in {{activeMonth}}</h4>
          <span>
            {{formatCurrency(activeProject.usage.total_api_spending, activeProject.usage.currency, false, true)}}
          </span>
        </div>
        <div class="chart-div" v-if="activeProject && activeProject.usage
          && activeProject.usage.total_api_spending > 1">
          <chart :chartType="'line'"
          :dataArray="linechartContent" :dataSets="chartDataSets"
          :labels="linelabelData" :borderColor="chart.borderColor"
          :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
        </div>
        <div class="chart-div" v-else-if="activeProject && activeProject.usage
          && activeProject.usage.total_api_spending <= 0">
          <chart :chartType="'line'"
          :options="emptyState.options"
          :dataArray="linechartContent" :dataSets="chartDataSets"
          :labels="linelabelData" :borderColor="chart.borderColor"
          :borderWidth="chart.borderWidth" :elementRef="'yourChart'"/>
          <div class="empty-state-graph">
            <hr>
          </div>
        </div>
        </div>
        <div class="loader" v-else>
          <div class="skeleton-box"></div>
        </div>
      </div>
      <div class="daily-usage usage-chart">
        <h3 class="chart-header">API Requests - {{monthName}},
          {{activeMonth.split('-')[0]}}</h3>
        <div class="daily-usage__chart-wrapper" v-if="chartLoaded && activeProject.loaded">
          <div class="daily-usage__amount-wrapper">
            <h4>Total API Requests in {{monthName}}</h4>
            <span>{{activeProject.usage.total_api_hits.toLocaleString('en-US')}}</span>
          </div>
          <div class="chart-div" v-if="activeProject && activeProject.usage
            && activeProject.usage.total_api_hits > 1">
            <line-chart :chartType="'line'"
            :dataArray=" activeProject && activeProject.usage && Object.values(activeProject.usage.day_wise_api_hits)"
            :dataSets="chartDataSets"
            :labels="apihitslabel" :borderColor="linechart.borderColor"
            :borderWidth="linechart.borderWidth" :elementRef="'yourChart'"/>
          </div>
          <div class="chart-div" v-else-if="activeProject && activeProject.usage
            && activeProject.usage.total_api_hits <= 0">
            <line-chart :chartType="'line'"
            :options="emptyState.options"
            :dataArray=" activeProject && activeProject.usage && Object.values(activeProject.usage.day_wise_api_hits)"
            :dataSets="chartDataSets"
            :labels="apihitslabel" :borderColor="linechart.borderColor"
            :borderWidth="linechart.borderWidth" :elementRef="'yourChart'"/>
            <div class="empty-state-graph api-hits">
              <hr>
            </div>
          </div>
        </div>
        <div class="loader" v-else>
          <div class="skeleton-box"></div>
        </div>
      </div>
    </div>
    <div v-else class="usage-report">
      <div class="usage-report__api-usage">
        <h3 class="api-usage__title">Usage for
          <date-picker @monthchange="fetchUsage($event)" :activeMonth="activeMonth"
            :projectCreatedDate="activeProject.created"
          />
        </h3>
        <api-usage
        v-if="activeProject"
        :activeMonth="activeMonth"
        :maxLen="maxLen"></api-usage>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.usage-report {
  &__api-usage {
    position: relative;
  }
  .api-usage__title {
    @include font-styles(
      $type: 'display',
      $size: 'xs',
      $weight: 'bold'
    );
    color: var(--global--main--title--Color);
    padding-bottom: size(30);
  }
  .chart-header {
    @include font-styles(
      $type: 'text',
      $size: 'lg',
      $weight: 'bold'
    );
    color: var(--global--main--title--Color);
    padding-bottom: size(14);
  }
  .daily-usage, .resource-usage {
    margin-bottom: size(60);
  }
  .usage-chart {
    margin-top: size(60);
  }
  .daily-usage__chart-wrapper {
    background: var(--global--white--Color);
    box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1), 0 size(2) size(1) rgba(0, 0, 0, 0.06),
     0 size(1) size(1) rgba(0, 0, 0, 0.08);
    border-radius: size(8);
    padding-top: size(30);
    .daily-usage__amount-wrapper, .daily-usage__usage-wrapper {
      display: inline-block;
      margin-bottom: size(30);
      padding-left: size(30);
      h4 {
        @include font-styles(
          $type: 'label',
          $size: 'lg',
          $weight: 'normal'
        );
        padding-bottom: size(6);
      }
      span {
        @include font-styles(
          $type: 'text',
          $size: 'lg',
          $weight: 'bold'
        );
      }
    }
  }
}
</style>
<style lang="scss">
.usage-report {
  .daily-usage, .resource-usage {
    .widget-box {
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 0;
      .chart-area-wrapper {
        width: 100%;
        overflow-x: auto;
        canvas {
          height: size(300);
        }
      }
    }
  }
  .empty-chart {
    position: absolute;
    bottom: size(25);
    padding: size(250) 0 0;
    left: 0;
    right: 0;
    background: var(--global--white--Color);
    canvas {
      height: size(50)!important;
    }
  }
  .chart-div {
    position: relative;
    canvas {
      max-height: size(430);
    }
  }
  .empty-state-graph {
    padding: size(200) 0;
    background: var(--global--white--Color);
    top: size(-30);
    right: 0;
    position: absolute;
    left: 0;
    hr {
      height: size(3);
      background: #0085FF;
    }
    &.api-hits {
      hr {
        background: #0085FF;
      }
    }
  }
  .loader {
    --loader--box--BackgroundColor: #0055bf33;

    background: var(--loader--box--BackgroundColor);
    width: 100%;
    animation: fadein .5s;
    border-radius: size(10);
    height: size(500);
    &.usage-loader {
      height: size(280);
      margin-bottom: size(20);
    }

    .skeleton-box {
      display: inline-block;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-color: var(--loader--box--BackgroundColor);
      border-radius: size(10);

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(#f8f6ff, 0) 0,
          rgba(#f8f6ff, 0.2) 20%,
          rgba(#f8f6ff, 0.5) 60%,
          rgba(#f8f6ff, 0)
        );
        animation: shimmer 1s infinite;
        content: '';
      }
    }
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
</style>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { currencyMethodsMixin } from '@/mixin/currency';
import Chart from '@/components/Charts/Chart.vue';
import lineChart from '@/components/Charts/lineChart.vue';
import ApiUsage from '../components/ApiUsage.vue';
import DatePicker from '../components/DatePicker.vue';

export default {
  name: 'usageReport',
  mixins: [currencyMethodsMixin],
  components: {
    ApiUsage,
    Chart,
    DatePicker,
    lineChart,
  },
  data() {
    return {
      chartLoaded: false,
      maxLen: null,
      linechartData: null,
      barchartData: null,
      apichartData: null,
      chartInfo: {
        borderWidth: 0,
        options: { responsive: true, maintainAspectRatio: true },
      },
      chart: {
        borderWidth: 2,
        options: {
          responsive: true,
          maintainAspectRatio: true,
        },
      },
      emptygraph: {
        options: {
          tooltips: {
            enabled: false,
          },
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                suggestedMin: 0,
              },
            }],
            yAxes: [{
              display: false,
              gridLines: {
                display: false,
              },
            }],
          },
        },
      },
      resourceEmptyState: {
        labels: [
          'Resource 1',
          'Resource 2',
          'Resource 3',
          'Resource 4',
          'Resource 5',
          'Resource 6',
          'Resource 7',
          'Resource 8',
          'Resource 9',
          'Resource 10',
        ],
        data: [0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2, 0.2],
      },
      emptyState: {
        options: {
          tooltips: {
            enabled: false,
          },
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 20,
              },
            }],
            yAxes: [{
              display: false,
              gridLines: {
                display: false,
              },
            }],
          },
        },
      },
      linechart: {
        borderWidth: 2,
        options: { responsive: true, maintainAspectRatio: true },
      },
      linelabelData: null,
      linechartContent: null,
      apihitslabel: null,
      apihitsContent: null,
      barlabelData: null,
      barchartContent: null,
      activeMonth: null,
      monthName: null,
    };
  },
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
    }),
  },
  created() {
    this.fetchData();
  },

  watch: {
    activeProject(val, old) {
      if (old.key && (val.key !== old.key)) {
        this.fetchData();
      }
    },
  },

  methods: {
    fetchUsage(details) {
      const params = {};
      params.month = details.month;
      params.year = details.year;
      if (details.changeMonth) {
        params.changeMonth = details.changeMonth;
      }
      this.activeMonth = `${params.year}-${params.month}`;
      this.monthName = moment(params.month, 'MM').format('MMMM');
      this.fetchData(params);
    },
    fetchData(_params) {
      const params = _params || {};
      this.chartLoaded = false;
      if (this.activeProject.loaded) {
        if (this.$route.query && this.$route.query.month
         && this.$route.query.year && !params.changeMonth) {
          const currentYear = this.$route.query.year;
          const currentMonth = this.$route.query.month;
          this.activeMonth = `${currentYear}-${currentMonth}`;
          this.monthName = moment(currentMonth, 'MM').format('MMMM');
          params.month = currentMonth;
          params.year = currentYear;
        } else if (!params.month) {
          const currentYear = moment().format().split('-')[0];
          const currentMonth = moment().format().split('-')[1];
          this.activeMonth = `${currentYear}-${currentMonth}`;
          this.monthName = moment(currentMonth, 'MM').format('MMMM');
          params.month = currentMonth;
          params.year = currentYear;
        }
        params.projectKey = this.activeProject.key;
        this.$store.dispatch('project/fetchProjectUsage', { params }).then(() => {
          this.fetchResourceUsage();
          this.fetchDaywiseUsage();
          this.fetchApiUsage();
          this.chartLoaded = true;
        }).catch(() => {
          console.log('error');
        });
      }
    },
    fetchResourceUsage() {
      this.barchartData = this.activeProject && this.activeProject.usage
      && this.activeProject.usage.resource_wise_spending;
      this.barlabelData = [];
      this.barchartContent = [];
      if (this.barchartData) {
        Object.values(this.barchartData).forEach((val) => {
          let name = [];
          let content = [];
          name = val.name;
          content = val.total;
          this.barlabelData.push(name);
          this.barchartContent.push(content);
        });
      }
    },
    fetchDaywiseUsage() {
      this.linechartData = this.activeProject && this.activeProject.usage
        && this.activeProject.usage.day_wise_spending;
      if (this.linechartData) {
        this.linechartContent = Object.values(this.linechartData);
        const keyName = Object.keys(this.linechartData);
        for (let j = 0; j < keyName.length; j += 1) {
          keyName[j] = moment(keyName[j]).format('MMM DD');
        }
        this.linelabelData = keyName;
      }
    },
    fetchApiUsage() {
      this.apichartData = this.activeProject && this.activeProject.usage
        && this.activeProject.usage.day_wise_api_hits;
      if (this.apichartData) {
        this.apihitsContent = Object.values(this.apichartData);
        const key = Object.keys(this.apichartData);
        for (let i = 0; i < key.length; i += 1) {
          key[i] = moment(key[i]).format('MMM DD');
        }
        this.apihitslabel = key;
      }
    },
  },
};
</script>
